.ol-map {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
}

.ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-full-screen {
    top: .5em;
    right: .5em;
}